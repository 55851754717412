<template>
  <div class="v-stack h-stretch gap-3">
    <div ref="overlay" class="overlay">
      <div class="card dynamic">
        <div class="content mt-3">
          <div class="v-stack h-stretch gap-5">
            <div>Are you sure you want to delete this item?</div>
          </div>
        </div>
        <div class="footer gap-3 h-space-between mt-3">
          <button @click="dismiss()" class="submit">Cancel</button>
          <button @click="remove()" class="edit">Delete</button>
        </div>
      </div>
    </div>

    <div class="card light border h-stretch">
      <div class="content h-stretch">
        <div class="v-stack gap-3 h-stretch">
          <label class="text-left required">Name</label>
          <input type="text" v-model="item.name" />
          <label class="text-left required">Category</label>
          <select v-model="item.category">
            <option
              v-for="category in categories"
              :key="category"
              :value="category"
            >
              {{ category.charAt(0).toUpperCase() + category.slice(1) }}
            </option>
          </select>
          <label class="text-left">URL</label>
          <input v-model="item.url" type="text" />
          <label class="text-left required">Login</label>
          <input v-model="item.login" type="text" />
          <label class="text-left required">Password</label>
          <input v-model="item.password" type="text" />
          <label class="text-left">Description</label>
          <textarea v-model="item.description" />
          <div class="h-stack h-space-between">
            <div v-if="!id"></div>
            <button v-if="id" @click="removeDialog()" class="edit">
              Delete
            </button>
            <button class="submit" @click.prevent="submit()">
              {{ buttonLabel }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import constants from "@/constants.js";

export default {
  props: ["id"],
  data() {
    return {
      item: {},
      categories: (() => {
        const cat = ["All"].concat(
          constants.xfilesCategories.sort((a, b) => a.localeCompare(b, "cs"))
        );
        cat.splice(cat.indexOf("dalsi"), 1);
        cat.push("dalsi");
        return cat;
      })(),
    };
  },
  methods: {
    ...mapActions([
      "getXFilesItem",
      "addXFilesItem",
      "updateXFilesItem",
      "removeXFilesItem",
    ]),
    submit() {
      if (this.id) {
        this.updateXFilesItem(this.item)
          .then(() => {
            this.$router.go(-1);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.addXFilesItem(this.item)
          .then(() => {
            this.$router.go(-1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    removeDialog() {
      this.$refs.overlay.classList.add("show");
    },
    dismiss() {
      this.$refs.overlay.classList.remove("show");
    },
    remove() {
      this.removeXFilesItem(this.id)
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
      this.dismiss();
    },
  },
  computed: {
    buttonLabel() {
      if (this.id) {
        return "Submit";
      }
      return "Add";
    },
  },
  mounted() {
    if (this.id) {
      this.getXFilesItem(this.id)
        .then((item) => {
          this.item = item;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
};
</script>
